<template>
  <v-skeleton-loader
    type="image"
    class="configuration"
    v-if="!account"
  />
  <v-card
    class="configuration"
    v-else
  >
    <v-card-title>
      {{$t('t.Configuration')}}
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col>
          <picker
            document-types="credit-hold-reasons"
            :label="$t('t.CreditHoldReason')"
            :pre-items="notOnOrderHold"
            :selected-id.sync="creditHoldReasonId"
            class="mt-5"
            top
          />
        </v-col>
        <v-col>
          <v-text-field
            :label="$t('t.ImportKey')"
            :value="account.importKey"
            readonly
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col v-if="importSettings.workQueueAllocationAutomatic">
          <v-checkbox
            class="text-no-wrap"
            :label="$t('t.WorkQueueAllocationAutomatic')"
            v-model="automaticWorkqueueAssign"
            :disabled="!$store.getters.currentUserHasPermission('AccountEdit')"
          />
        </v-col>
        <v-col>
          <v-checkbox
            class="text-no-wrap"
            :label="$t('t.DunningBlocked')"
            v-model="dunningBlocked"
            :disabled="!$store.getters.currentUserHasPermission('AccountCanSwitchDunningState')"
          />
        </v-col>
        <v-col>
          <v-checkbox
            class="text-no-wrap"
            :label="$t('t.ExcludedFromDSO')"
            v-model="excludeFromDso"
            :disabled="!$store.getters.currentUserHasPermission('AccountEdit')"
          />
        </v-col>
        <v-col>
          <v-checkbox
            class="text-no-wrap"
            v-if="isExternalRiskType"
            :label="$t('t.RiskMonitored')"
            v-model="riskMonitored"
            :disabled="!$store.getters.currentUserHasPermission('AccountCanChangeRiskMonitoring')"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <country-iso-2-picker v-model="countryIso2" />
        </v-col>
        <v-col v-if="isExternalRiskType">
          <v-text-field
            :label="$t('t.ExternalRiskNumber')"
            :rules="externalRiskNumberRules"
            v-model="externalRiskNumber"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  components: {
    Picker: () => import('@/components/picker'),
    CountryIso2Picker: () => import('@/components/country-iso2-picker')
  },
  computed: {
    creditHoldReasonId: {
      get () {
        return this.account.creditHoldReasonId
      },
      set (id) {
        this.$emit('update:account', Object.assign({}, this.account, { creditHoldReasonId: id }))
      }
    },
    automaticWorkqueueAssign: {
      get () {
        return this.account.automaticWorkqueueAssign
      },
      set (automaticWorkqueueAssign) {
        this.$emit('update:account', Object.assign({}, this.account, { automaticWorkqueueAssign }))
      }
    },
    countryIso2: {
      get () {
        return this.account.countryIso2
      },
      set (countryIso2) {
        this.$emit('update:account', Object.assign({}, this.account, { countryIso2 }))
      }
    },
    onOrderHold: {
      get () {
        return this.account.onOrderHold
      },
      set (onOrderHold) {
        this.$emit('update:account', Object.assign({}, this.account, { onOrderHold }))
      }
    },
    dunningBlocked: {
      get () {
        return this.account.dunningBlocked
      },
      set (dunningBlocked) {
        this.$emit('update:account', Object.assign({}, this.account, { dunningBlocked }))
      }
    },
    excludeFromDso: {
      get () {
        return this.account.excludeFromDso
      },
      set (excludeFromDso) {
        this.$emit('update:account', Object.assign({}, this.account, { excludeFromDso }))
      }
    },
    externalRiskNumber: {
      get () {
        return this.account.externalRiskNumber
      },
      set (externalRiskNumber) {
        this.$emit('update:account', Object.assign({}, this.account, { externalRiskNumber }))
      }
    },
    externalRiskNumberRules () {
      return [v => (v?.length || 0) <= 50 || this.$t('t.MaxLength', { value: 50 })]
    },
    gacNumber: {
      get () {
        return this.account.gacNumber
      },
      set (gacNumber) {
        this.$emit('update:account', Object.assign({}, this.account, { gacNumber }))
      }
    },
    isExternalRiskType () {
      return this.riskCategoryType > 1
    },
    riskMonitored: {
      get () {
        return this.account.riskMonitored
      },
      set (riskMonitored) {
        this.$emit('update:account', Object.assign({}, this.account, { riskMonitored }))
      }
    }
  },
  data () {
    return {
      notOnOrderHold: [{
        name: this.$t('t.NotOnOrderHold'),
        id: null
      }]
    }
  },
  props: {
    account: Object,
    importSettings: Object,
    riskCategoryType: Number
  }
}
</script>
<style lang="stylus" scoped>
.v-card__text
  display flex
  flex-direction row
  flex-wrap wrap

.v-text-field, .v-input--checkbox
  margin-right 1em
</style>
